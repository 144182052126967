import { Box, Button, CircularProgress,Typography } from "@mui/material";
import React, { useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo: string = require("../assets/logo_wilya_dark.svg").default;

interface JsonData {
    [key: string]: Record<string, string>;
}

const I18nFileGenerator: React.FC = () => {
    const [combinedJson, setCombinedJson] = useState<JsonData | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(null); // Reset any previous errors
        setLoading(true); // Show loading indicator
        const files = event.target.files;
        if (!files || files.length === 0) {
            setError("Please select at least one JSON file.");
            setLoading(false);
            return;
        }

        type LanguageMap = Record<string, string>;
        type FileMap = Record<string, LanguageMap>;

        const combined: Record<string, LanguageMap> = {};
        const fileMap: FileMap = {}; // Ensure this matches your actual data structure

        try {
            // Read all selected files
            const filePromises = Array.from(files).map(async (file) => {
                const content = await file.text();
                const language = file.name.split(".")[0]; // Extract language from filename (e.g., 'english')
                fileMap[language] = JSON.parse(content);
            });

            await Promise.all(filePromises);

            // Combine the JSON files
            const allKeys = new Set<string>();

            Object.values(fileMap).forEach((data) => {
                Object.keys(data).forEach((key) => allKeys.add(key));
            });

            allKeys.forEach((key) => {
                if (!combined[key]) {
                    combined[key] = {};
                }
                Object.entries(fileMap).forEach(([language, data]) => {
                    combined[key][language] = data[key] || "";
                });
            });
            setCombinedJson(combined);
            setLoading(false);
        } catch (error) {
            console.error("Error reading files:", error);
            setError("Failed to parse JSON files. Ensure all files are valid JSON.");
            setLoading(false);
        }
    };

    const handleDownload = () => {
        if (!combinedJson) return;

        const blob = new Blob([JSON.stringify(combinedJson, null, 2)], {
            type: "application/json",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "combined.json";
        link.click();
        URL.revokeObjectURL(url);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            pt={16}
            px={3}
            height="100vh"
            width="100%"
            bgcolor="#f5f5f5"
            textAlign="left"
        >
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Box>
                    <img src={logo} alt="Logo" height={75} style={{paddingLeft: "8px"}}/>
                    <Box px={1.75} mt={4}>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "18px",
                                fontWeight: 500,
                                color: "rgba(0,0,0,0.87)",
                                textAlign: "left",
                                padding: "8px",
                            }}
                        >
                            i18n JSON File Combiner
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "rgba(0,0,0,0.57)",
                                marginTop: "8px",
                                textAlign: "left",
                                padding: "8px",
                            }}
                        >
                            Upload JSON files for different languages to combine them.
                        </Typography>
                        <input
                            type="file"
                            multiple
                            accept=".json"
                            onChange={handleFileChange}
                            style={{ margin: "10px 0", padding: "8px" }}
                        />
                        {error && (
                            <Typography sx={{ color: "red", fontSize: "14px", fontFamily: "Roboto", marginTop: "8px" }}>
                                {error}
                            </Typography>
                        )}
                        {loading && (
                            <Box sx={{ marginTop: "16px" }}>
                                <CircularProgress />
                            </Box>
                        )}
                        {combinedJson && !loading && (
                            <Box sx={{ textAlign: "center", marginTop: "32px" }}>
                                <Typography sx={{ fontFamily: "Roboto", fontSize: "14px", fontWeight: 400 }}>
                                    Files combined successfully!
                                </Typography>
                                <Button
                                    sx={{
                                        marginTop: "16px",
                                        textTransform: "none",
                                        fontFamily: "Roboto",
                                        fontSize: "15px",
                                        fontWeight: 500,
                                        color: "rgba(0,0,0,0.87)",
                                    }}
                                    onClick={handleDownload}
                                >
                                    Download Combined JSON
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default I18nFileGenerator;
