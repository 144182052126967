// Removed unused import
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Popover, Typography } from "@mui/material";
import {
	addDays,
	addWeeks,
	endOfWeek,
	isAfter,
	startOfDay,
	startOfWeek,
} from "date-fns";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { getDayNumber } from "./utils";
import WeekDatePicker from "./week-date-picker";

interface WeekNavigatorProps {
	date: Date;
	getDateRangeText: (date: Date) => string;
	setWeekCopyDate: (date: { start: Date; end: Date }) => void;
	weekCopyDate: { start: Date; end: Date };
	dispatch: any;
	fetchWeekShifts: any;
}

const WeekNavigator: React.FC<WeekNavigatorProps> = ({
	date,
	getDateRangeText,
	setWeekCopyDate,
	weekCopyDate,
	dispatch,
	fetchWeekShifts,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const weekStartDay = useSelector(
		(state: any) => state.user?.userData?.weekStartDay
	);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => setAnchorEl(null);

	const start = addDays(
		startOfWeek(weekCopyDate.start, {
			weekStartsOn: getDayNumber(weekStartDay),
		}),
		0
	);
	const end = addDays(
		endOfWeek(weekCopyDate.start, {
			weekStartsOn: getDayNumber(weekStartDay),
		}),
		0
	);

	const updateWeek = (weeks: number) => {
		const newStart = addWeeks(start, weeks);
		const newEnd = addWeeks(end, weeks);

		dispatch(
			fetchWeekShifts({
				startDate: startOfDay(newStart),
				endDate: startOfDay(newEnd),
			})
		);
		setWeekCopyDate({
			start: newStart,
			end: newEnd,
		});
	};

	const handleBack = () => updateWeek(-1);

	const handleForward = () => {
		if (!isAfter(new Date(weekCopyDate.end), new Date())) {
			updateWeek(1);
		}
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					justifyContent: "center",
					marginLeft: "16px",
					marginRight: "8px",
				}}
			>
				<Box
					onClick={handleBack}
					sx={{
						backgroundColor: "#F4F4F5",
						borderRadius: "4px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: "4px",
						cursor: "pointer",
					}}
				>
					<ChevronLeftIcon
						sx={{
							height: "24px",
							color: "rgba(0,0,0,0.56)",
						}}
					/>
				</Box>
			</Box>
			<Box>
				<Box
					sx={{
						minWidth: "186px",
						minHeight: "31px",
						backgroundColor: "rgba(47, 77, 139, 0.08)",
						padding: "5px 4px",
						borderRadius: "4px",
						cursor: "pointer",
					}}
					onClick={handleClick}
				>
					<Typography
						sx={{
							textAlign: "center",
							fontSize: "14px",
							fontColor: "#0E1B32",
							fontWeight: 500,
						}}
					>
						{getDateRangeText(date)}
					</Typography>
				</Box>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					transformOrigin={{
						horizontal: "center",
						vertical: "top",
					}}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				>
					<WeekDatePicker
						setWeekCopyDate={setWeekCopyDate}
						weekCopyDate={weekCopyDate}
					/>
				</Popover>
			</Box>
			<Box
				onClick={handleForward}
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					justifyContent: "center",
					marginLeft: "8px",
					cursor: "pointer",
				}}
			>
				<Box
					sx={{
						backgroundColor: "#F4F4F5",
						borderRadius: "4px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: "4.5px",
					}}
				>
					<ChevronRightIcon
						sx={{
							height: "24px",
							color: "rgba(0,0,0,0.56)",
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default WeekNavigator;
